<template>
  <v-card>
    <div v-if="successOrder">
      <v-card-title>
        <span class="ok-icon">
          <svg class="ok-icon__success" viewBox="0 0 24 24">
            <path
              fill="#4caf50"
              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            />
          </svg>
        </span>
      </v-card-title>
      <v-card-text class="d-flex flex-column text-center justify-content-start">
        <span class="text-h4">Ваша заявка успешно отправлена!</span>
        <span class="text-h6 mb-3">Ожидайте обратного звонка</span>
        <div class="d-flex justify-center mb-5">
          Ошиблись номером?
          <button class="return ml-1" type="button" @click="openForm">
            Вернуться к форме
          </button>
        </div>
      </v-card-text>
    </div>
    <div v-if="!successOrder">
      <v-card-title>
        <span class="ok-icon">
          <svg class="ok-icon__failed" viewBox="0 0 24 24">
            <path
              fill="red"
              d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"
            />
          </svg>
        </span>
      </v-card-title>
      <v-card-text class="d-flex flex-column text-center justify-content-start">
        <span class="text-h4">Что-то пошло не так!</span>
        <span class="text-h6 mb-3">Пожалуйста, попробуйте снова</span>
        <div class="d-flex justify-center mb-5">
          <button class="return" type="button" @click="openForm">
            Вернуться к форме
          </button>
        </div>
      </v-card-text>
    </div>
    <div class="w100 d-flex justify-content-end align-items-center h100px">
      <v-btn
        width="120"
        outlined
        color="primary"
        class="mx-a"
        @click="toggleState"
        >Закрыть</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";

export default {
  name: "OrderModal",
  data: () => ({
    dialog: true,
  }),
  computed: {
    ...mapGetters({
      successOrder: names.ORDER_SUCCESS,
    }),
  },

  mounted() {},
  methods: {
    ...mapActions({
      currentModalState: names.ACTION_SAVE_MODAL_ACTIVE,
      clearObjDoctorDetail: names.ACTION_MAIN_SEARCH_DOCTOR_DETAIL_CLEAR,
      clearObjClinicDetail: names.ACTION_MAIN_SEARCH_CLINIC_DETAIL_CLEAR,
    }),
    toggleState() {
      this.dialog = false;
      this.currentModalState(this.dialog);
      document.querySelector("body").style.overflow = "visible";
    },
    openForm() {
      this.toggleState();
      this.$drawer.showDrawer(true, "Fast");
      setTimeout(
        () => [this.clearObjDoctorDetail(), this.clearObjClinicDetail()],
        500
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/_helpers.scss";

button {
  outline: none !important;
}

.h100px {
  height: 100px;
}

.v-card__title {
  height: 150px;
}

.v-card__text {
  padding-top: 20px;
  min-height: 150px;
}

.ok-icon {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background: white;
  border-radius: 88px;
  -webkit-animation: fall-in 0.75s;
  -moz-animation: fall-in 0.75s;
  -o-animation: fall-in 0.75s;
  animation: fall-in 0.75s;

  &__success {
    width: 150px;
    height: 150px;
  }
  &__failed {
    width: 100%;
    height: 100%;
  }
}

@-webkit-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-moz-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-o-keyframes fall-in {
  0% {
    -ms-transform: scale(3, 3);
    -webkit-transform: scale(3, 3);
    transform: scale(3, 3);
    opacity: 0;
  }
  50% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  60% {
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
  }
  100% {
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.return {
  color: $dark-blue;

  &:hover {
    text-decoration: underline;
  }
}
</style>
