<template>
  <div :class="b()">
    <v-dialog max-width="500px" v-model="currentModalState" persistent>
      <order-modal />
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6">
        <h3>ФИО</h3>
        <v-text-field
          class="form_control"
          label="ФИО"
          v-model="name"
          outlined
          clearable
          dense
          :single-line="true"
          :rules="nameRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <h3>Телефон</h3>
        <v-text-field
          class="form_control"
          label="(999) 999-9999"
          v-model="phone"
          prefix="+7"
          outlined
          clearable
          :single-line="true"
          :rules="phoneRules"
          v-mask="'(###) ###-####'"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <h3>День</h3>
        <v-select
          class="form_control"
          :items="orderDay"
          item-text="name"
          item-value="value"
          label="Предпочтительное"
          dense
          outlined
          :single-line="true"
          v-model="selectOrderDay"
          :append-icon="icons.mdiMenuDown"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <h3>Время записи</h3>
        <v-select
          class="form_control"
          :items="orderTime"
          item-text="name"
          item-value="value"
          label="Предпочтительное"
          v-model="selectOrderTime"
          height="40"
          outlined
          dense
          :single-line="true"
          :append-icon="icons.mdiMenuDown"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <h3>Комментарий</h3>
        <v-textarea
          v-model="comment"
          counter
          maxlength="120"
          full-width
          single-line
          outlined
          dense
          rows="2"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          class="mt-0 pt-0"
          v-model="personalData"
          :off-icon="icons.mdiCheckboxBlankOutline"
          :on-icon="icons.mdiCheckboxMarked"
          label="Согласие на обработку персональных данных"
        ></v-checkbox>
        <v-checkbox
          class="mt-0 pt-0"
          v-if="childrensChek()"
          v-model="childCheck"
          :off-icon="icons.mdiCheckboxBlankOutline"
          :on-icon="icons.mdiCheckboxMarked"
          label="Записываю ребенка"
        ></v-checkbox>
        <v-expand-transition slot="append">
          <v-text-field
                  class="form_control"
                  label="ФИО ребенка"
                  v-model="nameChild"
                  v-if="childCheck"
                  outlined
                  clearable
                  dense
                  :single-line="true"
                  :rules="nameRules"
          ></v-text-field>
        </v-expand-transition>
        <v-expand-transition slot="append">
          <v-text-field
                  class="form_control"
                  label="Дата рождения ребенка"
                  v-model="dateChild"
                  v-if="childCheck"
                  outlined
                  clearable
                  dense
                  :single-line="true"
                  v-mask="'##.##.####'"
          ></v-text-field>
        </v-expand-transition>
        <v-checkbox
          class="mt-0 pt-0"
          v-model="smsNotification"
          :off-icon="icons.mdiCheckboxBlankOutline"
          :on-icon="icons.mdiCheckboxMarked"
          label="Я хочу получить смс-оповещение"
        ></v-checkbox>
        <p class="font-weight-light">
          После отправки заявки с вами свяжется оператор колл-центра. Вы сможете
          задать все интересующие вопросы и выбрать наиболее подходящее время
          записи.
        </p>
      </v-col>
      <v-col cols="12">
        <v-btn
          outlined
          class="btn-detail"
          color="primary"
          @click="orderService"
          :disabled="disable"
          onclick="ym(64731667, 'reachGoal', 'ZAYAVKA'); ga('send', 'event', 'ZAYAVKA', 'click'); return true;"
        >
          Записаться
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderModal from "components/partials/OrderModal";
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import isUndefined from "lodash/isUndefined";

import {
  mdiArrowLeft,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiMenuDown,
} from "@mdi/js";
export default {
  name: "OrderForm",
  props: ["services"],
  components: { OrderModal },
  data: () => ({
    name: "",
    phone: "",
    comment: "",
    smsNotification: true,
    personalData: false,
    childCheck: false,
    selectOrderDay: [],
    selectOrderTime: [],
    icons: {
      mdiCheckboxBlankOutline,
      mdiCheckboxMarked,
      mdiMenuDown,
      mdiArrowLeft,
    },
    orderTime: [
      {
        id: 1,
        name: "Утреннее",
      },
      {
        id: 2,
        name: "Дневное",
      },
      {
        id: 3,
        name: "Вечернее",
      },
    ],
    orderDay: [
      {
        id: 1,
        name: "Будни",
      },
      {
        id: 2,
        name: "Выходные",
      },
    ],
    nameRules: [
      (value) => !!value || "Введите имя",
      (value) => value.length <= 50 || "Максимальная длина строки 50 символов",
      (value) =>
        /^[\sa-zA-Zа-яёА-ЯЁ-]+$/.test(value) ||
        "Имя может состоять только из букв, пробелов и тире",
    ],
    phoneRules: [
      (value) => !!value || "Введите телефон",
      (value) =>
        /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10}(\s*)?$/.test(value) ||
        "Введите корректный телефон",
    ],
    modalOrderActive: false,
    disable: false,
    nameChild: "",
    dateChild: "",
    sitekey: process.env.VUE_APP_SITEKEY,
  }),
  computed: {
    ...mapGetters({
      currentModalState: names.ORDER_MODAL_ACTIVE,
      selectedDoctorInfo: names.SELECTED_DOCTOR_INFO,
    }),
  },
  methods: {
    ...mapActions({
      getCurrentModalState: names.ACTION_SAVE_MODAL_ACTIVE,
      postOrder: names.ACTION_FAST_ORDER,
    }),
    childrensChek(){
      if (this.selectedDoctorInfo.childDoc !== null ){
        if (this.selectedDoctorInfo.childDoc === false ){
          return  false
        }
        return  true
      }
    },

    async orderService() {
      if (!this.name) {
        return this.$notifier.showMessage("Введите ФИО", "warning");
      }

      if (!this.nameChild && this.childCheck) {
        return this.$notifier.showMessage("Введите ФИО ребенка", "warning");
      }

      if (!this.dateChild && this.childCheck) {
        return this.$notifier.showMessage("Введите дату рождения ребенка", "warning");
      }

      if (!this.phone) {
        return this.$notifier.showMessage("Введите номер телефона", "warning");
      }
      if (this.phone[1] == 8) {
          return this.$notifier.showMessage("Введите номер телефона без восьмерки", "warning");
      }
      if (this.services.departmentName == "") {
        return this.$notifier.showMessage("Выберите клинку для записи к врачу", "warning");
      }

      if (!this.personalData) {
        return this.$notifier.showMessage(
            "Вы должны согласиться на обработку персональных данных",
            "warning"
        );
      }

      let orderPhone = this.phone
        .replace(/^\+[7]+/g, "")
        .match(/\d+/g)
        .join("");


      let selectOrderDay = !isUndefined(this.selectOrderDay.name)
        ? this.selectOrderDay.name
        : "";
      let selectOrderTime = !isUndefined(this.selectOrderTime.name)
        ? this.selectOrderTime.name
        : "";

      this.disable = true;
      this.$metrika.reachGoal('ZAYAVKA');
      await this.postOrder({
        name: this.name,

        phone: orderPhone,

        smsNotification: this.smsNotification,

        noDifferenceOrder: false,

        childCheck: this.childCheck,

        orderDay: selectOrderDay,

        orderTime: selectOrderTime,

        comment: this.comment,

        service: this.services,

        nameChild: this.nameChild,

        dateChild: this.dateChild,

      });

      this.modalOrderActive = true;
      this.getCurrentModalState(true);
      this.$drawer.toggleState(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.OrderForm {
  @include phone-only {
    padding-bottom: 60px;
  }
}
</style>
